import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { es } from "./es";
import { en } from "./en";
import { pt } from "./pt";

i18next.use(initReactI18next).init({
  lng: "pt",
  fallbackLng: "pt",
  resources: {
    pt: {
      translation: pt,
    },
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
  },
  returnObjects: false,
  returnedObjectHandler: (key, value, options) => value,
});

export default i18next;
