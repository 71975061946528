import { useState } from "react";
import IUser from "../../types/IUser";
import IRequestStatus from "../../types/IRequestStatus";
import { useTranslation } from "react-i18next";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
}

export default function useLogin() {
  const { t } = useTranslation();
  const [state, dispatch] = useState<IState>({
    requestStatus: IRequestStatus.SUCCESS,
    serverError: "",
  });
  const [yes, setYes] = useState<Boolean>(false);

  async function onLogin(params: IUser) {
    dispatchPending();
  }

  function dispatchPending() {
    dispatch({
      requestStatus: IRequestStatus.PENDING,
      serverError: "",
    });
  }

  function handleCaptcha(response: any) {
    console.log(response);
    setYes(true);
  }

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
    t: t,
    onLoginClick: onLogin,
    handleCaptcha,
    yes,
  };
}
