export const es = {
  welcome: "Bienvenido a una nueva forma de hacerlo aún mejor",
  how: "¿Cómo trabajamos?",
  work: "Nuestra empresa trabaja de forma interactiva. Clientes, analistas y diseñadores trabajan juntos para desarrollar productos. Los programadores, los diseñadores de diseño y el control de calidad hacen su magia para obtener los mejores resultados.",
  solutions: "Nuestras soluciones",
  for: "Nuestra empresa brinda soluciones para web, escritorio, iOS y Anrdroid.",
  net: (
    <>
      Nuestra red de trabajo es amplia. Clientes de
      <strong> Argentina</strong>, <strong> España</strong> y ahora de
      <strong> Brasil</strong>, confían en nuestro conocimiento y en la calidad
      de nuestros soluciones y productos
    </>
  ),
  product: "Productos para enseñanza",
  cms: "Gestor de contenidos",
  cmsData: (
    <>
      <p>
        Nuestro gestor de contenidos está diseñado para facilitar el aprendizaje
        y la formación tanto en instituciones educativas como en empresas. Para
        profesores y líderes de formación, esta plataforma permite crear,
        organizar y compartir recursos de manera sencilla, asegurando que tanto
        estudiantes como empleados reciban el contenido adecuado según su ritmo,
        nivel de competencia o rol. Además, es posible integrar evaluaciones y
        realizar un seguimiento del progreso de alumnos o empleados en tiempo
        real, promoviendo un aprendizaje continuo y dinámico.
      </p>

      <p>
        Para estudiantes y empleados, la herramienta ofrece acceso a materiales
        desde cualquier lugar y en cualquier momento, fomentando el aprendizaje
        autónomo y la colaboración entre compañeros o equipos de trabajo. Con
        una interfaz intuitiva, garantiza una experiencia accesible y eficiente
        para todos los usuarios, sin importar su nivel técnico.
      </p>

      <p>
        Esta solución integral está pensada para satisfacer las necesidades de
        formación en escuelas y empresas, adaptándose a diferentes contextos de
        aprendizaje.
      </p>
    </>
  ),
  enjoy: "Clientes que ya se benefician de nuestra plataforma",
  products: (
    <>
      <p>
        Nos especializamos en el desarrollo de soluciones educativas
        innovadoras, ofreciendo una gama de productos diseñados para potenciar
        el aprendizaje.
      </p>
      <p>
        Entre nuestras ofertas se incluyen un motor avanzado de ejercicios y
        actividades, soporte completo para plataformas LMS, y una integración
        sencilla con otras plataformas, lo que facilita su uso en cualquier
        entorno educativo.
      </p>
      <p>
        Además, brindamos atención continua para asegurar una experiencia sin
        interrupciones.
      </p>
      <p>
        También incorporamos inteligencia artificial (IA) para ayudar en la
        creación de contenido personalizado para estudiantes y la generación
        rápida de actividades.
      </p>
      <p>
        A través de nuestras soluciones gamificadas, transformamos el
        aprendizaje en una experiencia interactiva y motivadora.
      </p>
    </>
  ),
  like: (
    <>
      <h1>¿Interesasdo? ¡Habla con nosotros!</h1>
      <h1>Nodes In Motion. Socio en el éxito de su empresa.</h1>
      <p className="center">
        Llame ahora o envíe un mensaje a través de whatsapp o rellene el
        formulario a continuación
      </p>
    </>
  ),
  phone: "+55 (48) 97400-9729",
  phoneWs:
    "phone=5548974009729&text=Hi,%20I am%20interested%20in%20your%20services",
  city: "Florianópolis-SC Brasil",
  email: "info@nimbrasil.com",
  surname: "Apellido/Empresa",
  name: "Nombre",
  telephone: "Teléfono",
  message: "Mensaje",
  send: "Enviar",
  sending: "Enviando mensaje...",
  sended: "Mensaje enviado",
  sendError: "Ocurrió un problema al enviar.",
  choose: "Seleccione su lenguaje preferido",
  data: [
    {
      icon: "Rocket",
      title: "Super Fast",
      text: "En cada área el feedback es continua, logrando superar los obstáculos de forma rápida y precisa.",
      footer: "",
    },
    {
      icon: "Know",
      title: "Knowledge base",
      text: "Nuestra experiencia nos permite brindar soluciones efectivas.",
      footer: "",
    },
    {
      icon: "Ready",
      title: "Development Ready",
      text: "Nuestros clientes interactúan con sus proyectos a través de las actualizaciones proporcionadas continuamente.",
      footer: "",
    },
    {
      icon: "Friendly",
      title: "User Friendly",
      text: "Uno de nuestros pilares es desarrollar sistemas que sean simples y fáciles de entender y usar.",
      footer: "",
    },
    {
      icon: "Check",
      title: "Well Documented",
      text: "Un estricto control de la documentación acompaña a cada proyecto.",
      footer: "",
    },
    {
      icon: "Smart",
      title: "Super Powerful",
      text: "Nuestros productos están desarrollados con las últimas tecnologías.",
      footer: "",
    },
  ],
};
