export const en = {
  welcome: "Welcome to a new way to make it even better",
  how: "How do we work?",
  work: `Our company works interactively. Customers, analysts and designers work together to develop products. 
  Programmers, layout designers and QA do their magic to get the best results.`,
  solutions: "Our solutions",
  for: "Our company provides solutions for web, desktop, iOS and Anrdroid.",
  net: (
    <>
      Our network is wide. Customers from
      <strong> Argentine</strong>, <strong> Spain</strong> and now
      <strong> Brazil</strong>, trust in the knowledge and quality of our
      solutions and products.
    </>
  ),
  product: "Products for teaching",
  cms: "Content Management System",
  cmsData: (
    <>
      <p>
        Our content management system is designed to facilitate learning and
        training in both educational institutions and businesses. For teachers
        and training leaders, this platform allows for easy creation,
        organization, and sharing of resources, ensuring that both students and
        employees receive content suited to their pace, competency level, or
        role. Additionally, it enables the integration of assessments and
        real-time tracking of progress, promoting continuous and dynamic
        learning.
      </p>

      <p>
        For students and employees, the tool provides access to materials from
        anywhere at any time, encouraging self-directed learning and
        collaboration among peers or team members. With an intuitive interface,
        it ensures an accessible and efficient experience for all users,
        regardless of their technical level.
      </p>

      <p>
        This comprehensive solution is tailored to meet the training needs of
        both schools and businesses, adapting to various learning contexts.
      </p>
    </>
  ),
  enjoy: "Clients who are already benefiting from our platform",
  products: (
    <>
      <p>
        We specialize in the development of innovative educational solutions,
        offering a range of products designed to enhance learning.
      </p>

      <p>
        Our offerings include an advanced engine for exercises and activities,
        full support for LMS platforms, and seamless integration with other
        platforms, making it easy to use in any educational environment.
      </p>

      <p>
        Additionally, we provide continuous support to ensure an uninterrupted
        experience.
      </p>

      <p>
        We also integrate artificial intelligence (AI) to assist in creating
        personalized content for students and rapidly generating activities.
      </p>

      <p>
        Through our gamified solutions, we transform learning into an
        interactive and motivating experience.
      </p>
    </>
  ),
  like: (
    <>
      <h1>Interested? Speak with us!</h1>
      <h1>Nodes In Motion. Partner in the success of your company</h1>
      <p className="center">
        Contact us via whatsapp, phone call or filling the form below
      </p>
    </>
  ),
  phone: "+55 (48) 97400-9729",
  phoneWs:
    "phone=5548974009729&text=Hi,%20I am%20interested%20in%20your%20services",
  city: "Florianópolis-SC Brazil",
  email: "info@nimbrasil.com",
  surname: "Surname",
  name: "Name",
  telephone: "Phone number",
  message: "Message",
  send: "Send",
  sending: "Sending message...",
  sended: "Message sent",
  sendError: "There was a problem sending.",
  choose: "Choose your favorite language",
  data: [
    {
      icon: "Rocket",
      title: "Super Fast",
      text: "In each area the feedback is continuous, managing to overcome obstacles quickly and accurately.",
      footer: "",
    },
    {
      icon: "Know",
      title: "Knowledge base",
      text: "Our experience allows us to provide effective solutions.",
      footer: "",
    },
    {
      icon: "Ready",
      title: "Development Ready",
      text: "Our customers interact with their projects through continuously provided updates.",
      footer: "",
    },
    {
      icon: "Friendly",
      title: "User Friendly",
      text: "One of our pillars is to develop systems that are simple and easy to understand and use.",
      footer: "",
    },
    {
      icon: "Check",
      title: "Well Documented",
      text: "A strict documentation control follow each project.",
      footer: "",
    },
    {
      icon: "Smart",
      title: "Super Powerful",
      text: "Our products are developed with the latest technologies.",
      footer: "",
    },
  ],
};
