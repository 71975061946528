import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  HashRouter as Router,
  Navigate,
} from "react-router-dom";
import Header from "./pages/header/Header";
import Footer from "./pages/footer/Footer";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Logout from "./pages/logout/Logout";
import Toast from "./commons/toast/Toast";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

export default function App() {
  const ProtectedRoute = ({ children }) => {
    return alive ? children : <Navigate replace to="/" />;
  };

  useEffect(() => {}, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Toast />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
        <Footer />
      </Router>
    </I18nextProvider>
  );
}
