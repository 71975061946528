import { useContext, useState } from "react";
import IRequestStatus from "../../types/IRequestStatus";
import useManager from "../../hooks/useManager";
import ICard from "../../types/ICard";
import IUser from "../../types/IUser";
import api from "../../hooks/api";
import { useTranslation } from "react-i18next";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
  onSubmit: (data: IUser) => void;
  onChangeLang: (lang: string) => void;
}

export default function useHome() {
  const manager = useManager();
  const { t, i18n } = useTranslation();

  const [state, dispatch] = useState<IState>({
    requestStatus: IRequestStatus.SUCCESS,
    serverError: "",
    onSubmit: onSubmit,
    onChangeLang: changeLang,
  });

  async function onSubmit(data: IUser) {
    manager.notify({ msg: `🚀	${t("sending")}` });
    api
      .post(data)
      .then((json) => {
        manager.notify({
          msg: `🙂 ${t("sended")}`,
          wait: true,
          time: 1500,
          hideAfter: true,
        });
      })
      .catch((e) => {
        manager.notify({
          msg: `🙃 ${t("sendError")}.`,
          wait: true,
          time: 1500,
          hideAfter: true,
        });
      });
  }

  function changeLang(lang: string) {
    i18n.changeLanguage(lang);
  }

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
    onSubmit: state.onSubmit,
    onChangeLang: state.onChangeLang,
    t: t,
    tlang: i18n.language,
    settings: {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
    },
  };
}
