import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import game1 from "../../../assets/slider/game_1-min.png";
import game2 from "../../../assets/slider/game_2-min.png";
import game3 from "../../../assets/slider/game_3-min.png";
import game4 from "../../../assets/slider/game_4-min.png";
import game5 from "../../../assets/slider/game_5-min.png";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    fade: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
  };
  const data = [game1, game2, game3, game4, game5];
  return (
    <Slider {...settings}>
      {data.map((item, index) => (
        <div className="item" key={`__${index}`}>
          <img width={600} src={item} alt="gamification" />
        </div>
      ))}
    </Slider>
  );
}
