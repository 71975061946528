interface FooterProps {
  online: boolean;
  version: string;
}
export default function View(props: FooterProps) {
  return (
    <div className="footer">
      <div className={`wifi${!props.online ? " off" : ""}`}></div>
      <div className="status">{props.online ? "Online" : "Offline"}</div>
      <div className="status">Nodes in motion v{props.version}</div>
    </div>
  );
}
