import { createContext } from "react";
import IMessage from "../types/IMessage";

const DEFAULT_COLOR = "violet";

const userContext = createContext({
  color: DEFAULT_COLOR,
  setColor: (newColor: string) => {},
  setOnLine: (online: boolean) => {},
  online: true,
  alive: true,
  setAlive: (alive: boolean) => {},
});

export default userContext;
