import ICard from "../../types/ICard";
import { ReactComponent as Rocket } from "../../assets/icons/rocket.svg";
import { ReactComponent as Know } from "../../assets/icons/know.svg";
import { ReactComponent as Ready } from "../../assets/icons/ready.svg";
import { ReactComponent as Friendly } from "../../assets/icons/friendly.svg";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import { ReactComponent as Smart } from "../../assets/icons/smart.svg";

export default function Card(props: ICard) {
  const getIcon = (icon: string) => {
    switch (icon) {
      case "Rocket":
        return <Rocket />;
      case "Know":
        return <Know />;
      case "Ready":
        return <Ready />;
      case "Friendly":
        return <Friendly />;
      case "Check":
        return <Check />;
      case "Smart":
        return <Smart />;
    }
  };
  return (
    <div className="card">
      <div className="header">
        {getIcon(props.icon)}
        <div>{props.title}</div>
      </div>
      <div className="body">{props.text}</div>
      <div className="footer"></div>
    </div>
  );
}
