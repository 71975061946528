import Sas from "../../../assets/clients/sas.png";
import Jhonnys from "../../../assets/clients/burger.png";
import Clean from "../../../assets/clients/cleanup.png";
import Motorama from "../../../assets/clients/motorama.png";
export default function Clients(props) {
  return (
    <div className="flex column">
      <h1>{props.t("enjoy")}</h1>
      <div className="flex brands">
        <img width={60} src={Sas} alt="SaS seguridad" />
        <img width={90} src={Jhonnys} alt="Jhonnys Burger" />
        <img width={160} src={Clean} alt="Cleanup Floripa" />
        <img width={160} src={Motorama} alt="Motorama" />
      </div>
    </div>
  );
}
