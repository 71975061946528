import "./login.scss";

import useLogin from "./useLogin";
import View from "./components/view";
import IUser from "../../types/IUser";

interface IStart {
  onSubmit: (data: IUser) => void;
}

export default function Login(props: IStart) {
  const { t, handleCaptcha, yes } = useLogin();
  return (
    <div className="login">
      <View
        t={t}
        handleCaptcha={handleCaptcha}
        yes={yes}
        onSubmit={props.onSubmit}
        status=""
      />
    </div>
  );
}
