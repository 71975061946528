import axios from "axios";

export default axios.create({
  baseURL: "https://api.nimbrasil.com/v1",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "https://nimbrasil.com",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    Authorization: "",
  },
});
