import { useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

import IUser from "../../../types/IUser";

interface IUserLogin {
  onSubmit: (data: IUser) => void;
  handleCaptcha: (response: any) => void;
  yes: Boolean;
  status: "";
  t: any;
}

export default function View(props: IUserLogin) {
  const { register, handleSubmit } = useForm<IUser>();
  const [save, setSave] = useState(false);

  const onSubmitHandle = (data: IUser) => {
    if (props.yes) {
      setSave(true);
      props.onSubmit(data);
    }
  };

  return (
    <div className="item">
      <form onSubmit={handleSubmit(onSubmitHandle)}>
        <input
          required
          {...register("name")}
          type="text"
          placeholder={props.t("name")}
        />
        <input
          required
          {...register("surname")}
          type="text"
          placeholder={props.t("surname")}
        />
        <input
          required
          {...register("email")}
          type="email"
          placeholder="Email"
        />
        <input
          required
          {...register("phone")}
          type="phone"
          placeholder={props.t("telephone")}
        />
        <textarea
          {...register("message")}
          required
          placeholder={props.t("message")}
        ></textarea>

        <ReCAPTCHA
          sitekey="6LcsWZYoAAAAAONgKrNyFqCSp6Zg3I7XsRDsSFGV"
          onChange={props.handleCaptcha}
        />

        <button
          disabled={save || !props.yes}
          className="primary full"
          type="submit"
        >
          {props.t("send")}
        </button>
      </form>
    </div>
  );
}
