import { useEffect, useState } from "react";
import Observer from "../../manager/Observer";
import INotify from "../../types/INotify";

export default function useToast() {
  const [message, setMessage] = useState({} as INotify);

  useEffect(() => {
    Observer.prototype.callback = (props: INotify) => {
      setMessage(props);
      if (props.hideAfter) {
        setTimeout(() => {
          setMessage({ msg: "" });
        }, props.time || 2000);
      }
      if (props.wait) {
        setTimeout(() => {
          if (props.callback) props.callback();
        }, props.time || 200);
      }
    };
  }, []);

  return {
    data: message,
  };
}
