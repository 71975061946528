import { useContext, useEffect, useState } from "react";
import userContext from "../../context/Context";
import IRequestStatus from "../../types/IRequestStatus";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
  online: boolean;
  version: string;
}

export default function useFooter() {
  const { online } = useContext(userContext);

  const [state, dispatch] = useState<IState>({
    requestStatus: IRequestStatus.SUCCESS,
    serverError: "",
    online: online,
    version: process.env.REACT_APP_VERSION || "",
  });

  useEffect(() => {
    dispatch({
      requestStatus: IRequestStatus.SUCCESS,
      serverError: "",
      online: online,
      version: process.env.REACT_APP_VERSION || "",
    });
  }, [online]);

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
    online: online,
    version: "1.0.0",
  };
}
