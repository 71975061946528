import { useState } from "react";
import IRequestStatus from "../../types/IRequestStatus";
import { useNavigate } from "react-router-dom";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
}

export default function useLogout() {
  const navigate = useNavigate();

  const [state] = useState<IState>({
    requestStatus: IRequestStatus.SUCCESS,
    serverError: "",
  });

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
  };
}
