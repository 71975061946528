export const pt = {
  welcome: "Seja bem-vindo a uma nova maneira de fazer ainda melhor.",
  how: "Como trabalhamos?",
  work: "Nossa empresa trabalha de forma interativa. Clientes, analistas e designers atuam juntos para o desenvolvimento dos produtos. Programadores, designers de layout e controle de qualidade realizam toda sua magia para obter os melhores resultados.",
  solutions: "Nossas soluções",
  for: "Nossa empresa fornece soluções para web, desktop, iOS e Android.",
  net: (
    <>
      Nossa rede de trabalho é ampla. Parceiros de
      <strong> Argentina</strong>, <strong> Espanha</strong> e agora
      <strong> Brasil</strong>, confíam em nosso conhecimento e na qualidade de
      nossas soluções e produtos.
    </>
  ),
  product: "Produto para ensino",
  cms: "Gerenciador de Conteúdos",
  cmsData: (
    <>
      <p>
        Nosso gestor de conteúdos é projetado para facilitar o aprendizado e a
        formação tanto em instituições educacionais quanto em empresas. Para
        professores e líderes de treinamento, esta plataforma permite criar,
        organizar e compartilhar recursos de maneira simples, garantindo que
        tanto alunos quanto funcionários recebam o conteúdo adequado conforme
        seu ritmo, nível de competência ou função. Além disso, é possível
        integrar avaliações e realizar o acompanhamento do progresso de alunos
        ou funcionários em tempo real, promovendo um aprendizado contínuo e
        dinâmico.
      </p>

      <p>
        Para alunos e funcionários, a ferramenta oferece acesso aos materiais de
        qualquer lugar e a qualquer momento, incentivando o aprendizado autônomo
        e a colaboração entre colegas ou equipes de trabalho. Com uma interface
        intuitiva, garante uma experiência acessível e eficiente para todos os
        usuários, independentemente do nível técnico.
      </p>

      <p>
        Esta solução integral foi desenvolvida para atender às necessidades de
        formação em escolas e empresas, adaptando-se a diferentes contextos de
        aprendizado.
      </p>
    </>
  ),
  enjoy: "Clientes que já se beneficiam da nossa plataforma",
  products: (
    <>
      <p>
        Nos especializamos no desenvolvimento de soluções educacionais
        inovadoras, oferecendo uma gama de produtos projetados para
        potencializar o aprendizado.
      </p>

      <p>
        Entre nossas ofertas estão um motor avançado de exercícios e atividades,
        suporte completo para plataformas LMS e uma integração simples com
        outras plataformas, facilitando seu uso em qualquer ambiente
        educacional.
      </p>

      <p>
        Além disso, oferecemos suporte contínuo para garantir uma experiência
        sem interrupções.
      </p>

      <p>
        Também incorporamos inteligência artificial (IA) para auxiliar na
        criação de conteúdo personalizado para os alunos e na geração rápida de
        atividades.
      </p>

      <p>
        Com nossas soluções gamificadas, transformamos o aprendizado em uma
        experiência interativa e motivadora.
      </p>
    </>
  ),
  like: (
    <>
      <h1>Gostou? Então fale conosco!</h1>
      <h1>Nodes In Motion. Parceiro no sucesso da sua empresa.</h1>
      <p className="center">
        Ligue agora ou mande uma mensagem pelo whatsapp ou formulário abaixo
      </p>
    </>
  ),
  phone: "+55 (48) 97400-9729",
  phoneWs:
    "phone=5548974009729&text=Olá,%20estou%20interessado%20em%20seus%20serviços",
  city: "Florianópolis-SC Brasil",
  email: "info@nimbrasil.com",
  surname: "Nome",
  name: "Sobrenome",
  telephone: "Telefone (DDD)",
  message: "Mensagem",
  send: "Enviar",
  sending: "Enviando mensagem...",
  sended: "Mensagem enviada",
  sendError: "Ocorreu um problema ao enviar.",
  choose: "Escolha o seu idioma preferido",
  data: [
    {
      icon: "Rocket",
      title: "Super rápido",
      text: "Em cada área o feedback é contínuo, conseguindo superar os obstáculos com rapidez e precisão.",
      footer: "",
    },
    {
      icon: "Know",
      title: "Base de conhecimento",
      text: "Nossa experiência nos permite proporcionar soluções eficazes.",
      footer: "",
    },
    {
      icon: "Ready",
      title: "Pronto para Desenvolvimento",
      text: "Nossos clientes interagem com seus projetos através das atualizações fornecidas continuamente.",
      footer: "",
    },
    {
      icon: "Friendly",
      title: "Usabilidade amigável",
      text: "Um dos nossos pilares é desenvolver sistemas simples e fáceis de entender e utilizar.",
      footer: "",
    },
    {
      icon: "Check",
      title: "Bem documentado",
      text: "Um controle rigoroso da documentação acompanha cada rascunho.",
      footer: "",
    },
    {
      icon: "Smart",
      title: "Super-poderoso",
      text: "Nossos produtos são desenvolvidos com as mais recentes tecnologias.",
      footer: "",
    },
  ],
};
