import IUser from "../types/IUser";
import request from "./axios";

const get = () => {
  return request.get(`/customers`, {
    params: {},
  });
};

const post = (data: IUser) => {
  return request.post(`/customers`, data);
};

export default {
  get,
  post,
};
