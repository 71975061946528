import { nanoid } from "nanoid";
import Card from "../../../commons/cards/Card";
import ICard from "../../../types/ICard";

interface HomeProps {
  data: ICard[];
}

export default function View(props: HomeProps) {
  return (
    <div className="flex">
      {props.data.map((item) => {
        return (
          <Card
            key={nanoid()}
            icon={item.icon}
            title={item.title}
            text={item.text}
            footer={item.footer}
          />
        );
      })}
    </div>
  );
}
