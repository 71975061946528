import "./home.scss";
import useHome from "./useHome";
import View from "./components/view";
import Login from "../login/Login";
import { ReactComponent as Ws } from "../../assets/icons/ws.svg";
import { useState } from "react";
import SimpleSlider from "./components/simpleSlider";
import English from "../../assets/icons/united-kingdom.png";
import Spain from "../../assets/icons/spain.png";
import Brazil from "../../assets/icons/brazil.png";
import Clients from "./components/clients";

export default function Home() {
  const {
    requestStatus,
    serverError,
    onSubmit,
    t,
    onChangeLang,
    tlang,
    settings,
  } = useHome();
  const [lang, setLang] = useState(tlang);

  const clickHandler = (l: string) => {
    setLang(l);
    onChangeLang(l);
  };

  return (
    <div className="wrapper">
      <div className="flex speach">
        <img
          width={116}
          height={90}
          src="./assets/logo.png"
          alt="nim"
          title="nim logo"
        />
        <div>
          <h1>Nodes In Motion</h1>
          <h2>{t("welcome")}</h2>
        </div>
      </div>
      <div className="flex flags">
        <p>{t("choose")}</p>
        <button
          onClick={() => {
            clickHandler("pt");
          }}
          className={lang === "pt" ? "selected" : ""}
        >
          <img width={24} src={Brazil} alt="Brazil" />
        </button>
        <button
          onClick={() => {
            clickHandler("en");
          }}
          className={lang === "en" ? "selected" : ""}
        >
          <img width={24} src={English} alt="English" />
        </button>
        <button
          onClick={() => {
            clickHandler("es");
          }}
          className={lang === "es" ? "selected" : ""}
        >
          <img width={24} src={Spain} alt="Spain" />
        </button>
      </div>
      <div className="flex column">
        <div>
          <h1>{t("how")}</h1>
          <p>{t("work")}</p>
        </div>
      </div>
      <div className="sep"></div>
      <div className="flex">
        <View data={t("data") as any} />
      </div>
      <div className="sep"></div>
      <div className="flex column">
        <div>
          <h1>{t("solutions")}</h1>
          <p>{t("for")}</p>
          <p>{t("net")}</p>
        </div>
      </div>
      <div className="sep"></div>
      <div className="flex column">
        <h1>{t("product")}</h1>
      </div>
      <div className="wrapper-slider">
        <div className="slider-container">
          <SimpleSlider />
        </div>
      </div>
      <div className="flex column ngap">{t("products")}</div>
      <div className="sep"></div>
      <div className="flex column">
        <h1>{t("cms")}</h1>
      </div>
      <div className="sep"></div>
      <div className="flex column ngap">{t("cmsData")}</div>
      <div className="sep"></div>
      <Clients t={t} />
      <div className="sep"></div>
      <div className="flex column">
        <div>
          {t("like")}
          <div className="sep">
            <div className="flex center">
              <div className="block">
                <img
                  width={118}
                  height={92}
                  src="./assets/logoShadow.png"
                  title="nim logo"
                  alt="nim logo"
                />
                <div>
                  <p>
                    <Ws />
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?${t("phoneWs")}`}
                    >
                      {t("phone")}
                    </a>
                  </p>
                  <p>{t("city")}</p>
                  <p>
                    <a target="_blank" href={`mailto:${t("email")}`}>
                      {t("email")}
                    </a>
                  </p>
                </div>
              </div>
              <Login onSubmit={onSubmit} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
